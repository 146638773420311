import React, {useEffect} from "react";
import "./About.css";
import { useState } from "react";

const About = (props) => {
  const [state] = useState({
    name: "Sulav Sapkota",
    image_url: "",
    content: `
        Hi I am a Sulav Sapkota. I am good in Python Backend and React.js Frontend.
        `,
    socialMediaLinks: {
      facebook: "https://www.facebook.com",
      twitter: "https://www.twitter.com",
      instagram: "https://www.instagram.com",
      youtube: "https://www.youtube.com",
      linkedin: "https://www.linkedin.com",
    },
  });

  useEffect(() =>document.title = 'About | Sulav Sapkota',[])

  return (
    <div className="aboutCard">
      <div className="aboutImage">
        <img
          src="https://img.freepik.com/free-photo/rear-view-programmer-working-all-night-long_1098-18697.jpg?size=626&ext=jpg"
          alt=""
        />
      </div>
      <div className="aboutDescription">
        <div className="name">
          <h1>{state.name}</h1>
        </div>
        <div className="aboutContent">
          <p>{state.content}</p>
        </div>
        <div className="socialLinks">
          {Object.entries(state.socialMediaLinks).map((key) => (
            <i className={`fab fa-${key[0]}`} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;
