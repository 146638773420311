import React, { useState } from "react";
import "./postComment.css";
import Loader from '../../Loader/Loader'
import Success from '../../SuccessMessage/Success'

const PostComment = (props) => {

  const [loading,modifyLoading] = useState(false)
  const [msg,modifyMsgShow] = useState('')

  const UploadComment = (props) => {
    modifyLoading(true)
    let data = {
      full_name: document.querySelector("#full_name").value,
      comment: document.querySelector("#comment").value,
      post_id: props.id,
    };

    fetch("https://blogportfoliobackend.herokuapp.com/post_comment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    .then(resp => {
      modifyLoading(false);
      modifyMsgShow((<Success message="Comment Posted!"/>))
    });
  };
  return (
    <React.Fragment>
      <h1>Add Comment</h1>
      {msg}
      {loading ? (
        <Loader />
      ) : (
        <div className="form">
          <input
            id="full_name"
            style={{ width: "30%" }}
            type="text"
            placeholder="Full Name"
          />
          <textarea
            id="comment"
            style={{ height: "100px" }}
            placeholder="Comment"
          />
          <input
            type="submit"
            value="Post"
            className="exploreBtn"
            onClick={UploadComment.bind(this, props)}
          />
          <hr width="100%" />
        </div>
      )}
    </React.Fragment>
  );
};

export default PostComment;
