import React, { Component } from "react";
import "./Blog.css";
import BlogCard from "./ListPosts/BlogCard";
import Pagination from "../../components/Pagination/Pagination";
import Loader from "../../components/Loader/Loader";

class Blog extends Component {
  state = {
    posts: [],
    searched: [],
    postsPerPage: 4,
    currentPage: 1,
    totalPages: 0,
    loading: true,
  };

  fetchData() {
    fetch("https://blogportfoliobackend.herokuapp.com/")
      .then((resp) => resp.json())
      .then((data) =>
        this.setState({
          posts: data.reverse(),
          searched: data,
          loading: false,
        })
      )
      .then(() => {
        const indexOfLastPost =
          this.state.currentPage * this.state.postsPerPage;
        const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
        const currentPosts = this.state.searched.slice(
          indexOfFirstPost,
          indexOfLastPost
        );
        const pages = Math.ceil(
          this.state.searched.length / this.state.postsPerPage
        );
        this.setState({ searched: currentPosts, totalPages: pages });
      });
  }

  componentDidMount() {
    this.fetchData();
    document.title = "Blog | Sulav Sapkota";
  }
  componentDidUpdate() {
    document.title = "Blog | Sulav Sapkota";
  }

  increasePage(pagenum) {
    this.setState({ currentPage: pagenum });
    this.fetchData();
  }

  search() {
    let keyword = document.querySelector("#search").value.toLowerCase();
    let new_posts = this.state.posts.filter((post) =>
      post.title.toLowerCase().includes(keyword)
    );
    if ((keyword == null) | (keyword == "")) {
      this.fetchData();
    } else {
      this.setState({
        searched: new_posts.reverse(),
        totalPages: 0,
      });
    }
  }

  render() {
    return (
      <div>
        <h1>POSTS</h1>
        {this.state.loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <input
              id="search"
              className="blogSearch"
              placeholder="Search...."
              type="search"
            />
            <button
              className="exploreBtn"
              style={{ width: "40px" }}
              onClick={this.search.bind(this)}
            >
              <i className="fa fa-search"></i>
            </button>
            <div className="posts">
              {this.state.searched.map((post) => {
                let link = `blog/${post.id}`;
                return <BlogCard key={post.id} link={link} data={post} />;
              })}
            </div>
          </React.Fragment>
        )}
        <Pagination
          method={this.increasePage.bind(this)}
          total={this.state.totalPages}
          current_page={this.state.currentPage}
          last_page={this.state.totalPages}
        />
      </div>
    );
  }
}

export default Blog;
