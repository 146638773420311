import React, { useEffect } from 'react'
import './Success.css'


const Success = props =>{

    const sleep = () => {
        return new Promise(resolve => setTimeout(resolve,3000))
    }
    
    useEffect(async ()=>{
        await sleep()
        document.querySelector("#successCont").style.display= "none";
    },[])

    return(
    <p id="successCont" className="success">{props.message}</p>
    )
}

export default Success