import React from "react";
import { Link } from "react-router-dom";
import "./BlogCard.css";

const BlogCard = (props) => {
  let date = new Date(props.data.created_on);
  return (
    <div className="card">
      <div className="image">
        <img alt={props.data.title} src={props.data.image_url} />
      </div>
      <div className="content">
        <div className="title">
          <Link to={props.link}>
            <h1 title={props.data.title}>{props.data.title.split("").splice(0,30)}...</h1>
          </Link>
        </div>
        <div className="description">
          <p>
            {date.getFullYear()}/{date.getMonth()}/{date.getDate()} | &nbsp;
             {props.data.author}
          </p>
          <div className="content">{props.data.content.split("").splice(0,50)}...</div>
        </div>

        <div className="readmore">
          <Link to={props.link}>
            <br />
            <button className="exploreBtn">Read More</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
