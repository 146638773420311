import React, { useEffect, useState } from "react";
import './ListComment.css'
const ListComment = (props) => {
  const avatars = [
    "https://bit.ly/3otkssD",
    "https://bit.ly/33JKuA7",
    "https://bit.ly/2JJu2sF",
  ];
  const [comments, modifyComments] = useState([]);

  useEffect(() => {
    fetch(`https://blogportfoliobackend.herokuapp.com/get_comment/${props.id}`)
      .then((data) => data.json())
      .then((comment) => modifyComments(comment.reverse()));
  });

  return (
    <React.Fragment>
      <div className="comment_body">
        <h1>Recent Comments</h1>
        {comments.map((comment) => (
          <div key={comment.comment} className="comment_box">
            <div className="top">
              <img src={avatars[0]} alt="" />
              <h3>{comment.full_name}</h3>
            </div>
            <p>{comment.comment}</p>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default ListComment;
