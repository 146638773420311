import React, { useEffect, useState } from "react";
import "./Contact.css";
import Success from "../../components/SuccessMessage/Success";
import Loader from "../../components/Loader/Loader";

const Contact = (props) => {
  useEffect(() => {
    document.title = "Contact | Sulav Sapkota";
  }, []);
  const [msg, showMsg] = useState("");
  const [loading, modifyLoading] = useState(false);

  const sendEmailHandler = () => {
    modifyLoading(true);
    const data = {
      email: document.querySelector("#email").value,
      full_name: document.querySelector("#full_name").value,
      subject: document.querySelector("#subject").value,
      comment: document.querySelector("#message").value,
    };
    fetch("https://blogportfoliobackend.herokuapp.com/create_email", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((resp) => {
      showMsg(<Success message="Message sent successfully!" />);
      modifyLoading(false);
      const inp = document.querySelector(".contact").querySelectorAll("input");
      for (let x = 0; x < inp.length; x++) {
        inp[x].value = "";
      }
      document.querySelector("textarea").value = "";
    });
  };

  return (
    <div className="contact">
      <div className="contactHeader">
        <h1>Please fill the form below:</h1> <br />
        {loading ? <Loader /> : null}
        <p>{msg}</p>
      </div>
      <div className="contactForm">
        <form action="">
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="email"
              id="email"
              placeholder="Email Address"
            />
            <input
              className="form-control"
              type="text"
              name=""
              placeholder="Full Name"
              id="full_name"
            />
          </div>
          <div className="form-group">
            <input
              placeholder="Subject"
              className="form-control"
              type="text"
              name=""
              id="subject"
            />
          </div>

          <div className="form-group">
            <textarea
              className="form-control"
              name=""
              id="message"
              cols="30"
              rows="10"
              placeholder="Your message goes here...."
            ></textarea>
          </div>
        </form>
      </div>
      <button className="exploreBtn" onClick={sendEmailHandler}>
        Contact
      </button>
    </div>
  );
};
export default Contact;
