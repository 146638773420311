import React from "react";
import { useState, useEffect } from "react";
import "./PostDetail.css";
import Loader from "../../../components/Loader/Loader";
import CommentForm from "../../../components/Comment/PostComment/postComment";
import CommentList from "../../../components/Comment/ListComment/ListComment";
import UseScript from '../../../components/useScript/useScript'

const PostDetail = (props) => {
  const [post, modifyPost] = useState({
    post_detail: {},
    show_load: true,
  });

  useEffect(() => {
    fetch(
      `https://blogportfoliobackend.herokuapp.com/post_detail/${props.match.params.id}`
    )
      .then((resp) => resp.json())
      .then((data) => {
        modifyPost({ post_detail: data });
        post.show_load = false;
        document.title = post.post_detail.title;
      });
  });

  return (
    <div className="main">
      <UseScript />

      {post.show_load ? (
        /// <Loader />
        <Loader />
      ) : (
        <React.Fragment>
          <h1>{post.post_detail.title}</h1>
          <p
            style={{
              color: "grey",
              fontWeight: "100",
              fontFamily: "Roboto Condensed",
            }}
          >
            {post.post_detail.author} |{" "}
            {post.post_detail.created_on.split("").splice(0, 10)}
          </p>
          <img src={post.post_detail.image_url} alt="" />

          {post.post_detail.content.split("\n").map((par) => (
            <p key={Math.random()} className="post_detail">
              {par}
            </p>
          ))}

          <hr />
          <CommentForm id={props.match.params.id} />
          <CommentList id={props.match.params.id} />
        </React.Fragment>
      )}
    </div>
  );
};

export default PostDetail;
