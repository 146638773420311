import React, { useEffect } from "react";
import "./home.css";
import { Link } from "react-router-dom";

const Home = (props) => {
  useEffect(() => {
    document.title = "Home | Sulav Sapkota";
  }, []);
  return (
    <React.Fragment>
      <div className="main">
        <div className="text">
          <h1>
            Hi! <br /> I am Sulav Sapkota
          </h1>
          <p>I code!</p>

          <Link to="blog">
            <button className="exploreBtn">Explore</button>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
