import React from "react";
import "./Pagination.css";

const Pagination = (props) => {
  let pages = [];
  let prevBtn = null;
  let nextBtn = null;
  console.log(props.total);
  for (let i = 1; i < props.total + 1; i++) {
    pages.push(
      <button className="pagenum" onClick={() => props.method(i)}>
        {i}
      </button>
    );
  }

  if (props.current_page == 1) {
    prevBtn = (
      <button disabled className="paginate disabled exploreBtn">
        Previous
      </button>
    );
  } else {
    prevBtn = (
      <button
        
        className="paginate exploreBtn"
        onClick={() => props.method(props.current_page-1)}
      >
        Previous
      </button>
    );
  }

  if (props.current_page == props.last_page) {
    nextBtn = <button className="exploreBtn paginate disabled">Next</button>;
  } else {
    nextBtn = (
      <button className="exploreBtn paginate" onClick={() => props.method(props.current_page+1)}>
        Next
      </button>
    );
  }

  return (
    <div className="paginate_button">
      {prevBtn}
      {pages.map((page) => page)}
      {nextBtn}
    </div>
  );
};

export default Pagination;
