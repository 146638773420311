import React, { Component } from "react";
import Home from "../../directory/Home/Home";
import Blog from "../../directory/Blog/Blog";
import About from "../../directory/About/About";
import Contact from "../../directory/Contact/Contact";
import Navigation from "../Navigation/navigation";
import PostDetail from '../../directory/Blog/PostDetail/PostDetail'
import { BrowserRouter as Router, Route } from "react-router-dom";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = null;
  }

  render() {
    return (
      <Router>
        <Navigation />
          <Route exact path="/" component={Home} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/blog/:id" component={PostDetail} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
      </Router>
    );
  }
}

export default Layout;
