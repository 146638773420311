import React, { useEffect } from "react";
import postscribe from "postscribe";

const useScript = (props) => {
  useEffect(() => {
    const load = () => {
      // Build url params and make the ad call
      postscribe(
        "#ad",
        '<script data-ad-client="ca-pub-9400799510982088" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>'
      );
      postscribe(
        "#ad2",
        "<script data-cfasync='false' type='text/javascript' src='//p350529.clksite.com/adServe/banners?tid=350529_685386_13&type=slider&side=left&size=9&position=center&animate=on'></script>"
      );

      postscribe(
        "#ad3",
        "<script data-cfasync='false' type='text/javascript' src='//p350529.clksite.com/adServe/banners?tid=350529_685386_11'></script>"
      );
    };
    load();
  }, []);

  return (
    <div>
      <div id="ad"></div>
      <div id="ad2"></div>
      <div id="ad3"></div>
    </div>
  );
};

export default useScript;
